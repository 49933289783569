import React, { useCallback, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { TFuncKey, useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { from } from 'rxjs'
import * as Yup from 'yup'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  FormControlLabel,
  InputAdornment,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import {
  ContactWithoutPasswordPayload,
  getClientCode,
  getClientPortalHost,
  getVendorCode,
  IAccountType,
  RecaptchaActions,
  VendorCodes,
} from '@procom-labs/common'
import { PhoneInput, RadioGroupInputOption } from '@procom-labs/molecules'

import { useRecaptcha } from '@auth-portal/hooks'
import { authService } from '@auth-portal/services'
import { getCallbackURL, getOriginBaseUrl } from '@auth-portal/util'

interface FormValues {
  accountType: string
  firstName: string
  lastName: string
  contactNumber: string
  clientName: string
}

const AccountTypeOptions: RadioGroupInputOption[] = [
  {
    label: 'form.createAccount.client',
    value: IAccountType.Client,
  },
  {
    label: 'form.createAccount.contractor',
    value: IAccountType.Contractor,
  },
]

export const MultiSelectionForm: React.FC<{
  email: string
  handleClientCreated: () => void
  handleOpen: () => void
}> = ({ email, handleOpen, handleClientCreated }) => {
  const { t, i18n } = useTranslation('main')
  const { getRecaptchaToken } = useRecaptcha()
  const isContractorRouteAllowed = [
    VendorCodes.PCGL,
    VendorCodes.DEMO,
  ].includes(getVendorCode())

  const [accountType, setAccountType] = useState<string>(
    isContractorRouteAllowed ? '' : IAccountType.Client
  )
  const [submitIsLoading, setSubmitIsLoading] = useState(false)
  const initialValues: FormValues = {
    accountType: isContractorRouteAllowed ? '' : IAccountType.Client,
    firstName: '',
    lastName: '',
    contactNumber: '',
    clientName: '',
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('form.createAccount.firstNameError')),
    lastName: Yup.string().required(t('form.createAccount.lastNameError')),
    contactNumber:
      accountType === IAccountType.Client
        ? Yup.string()
            .required(t('form.createAccount.phoneNumberError'))
            .min(10, t('form.createAccount.phoneNumberInvalidLength'))
        : Yup.string().notRequired(),
    clientName:
      accountType === IAccountType.Client
        ? Yup.string().required(t('form.createAccount.companyNameError'))
        : Yup.string().notRequired(),
  })

  const handleGenerateOtp = useCallback(() => {
    from(getRecaptchaToken(RecaptchaActions.RequestResetPassword)).subscribe({
      next: (token) => {
        authService.generateSignInOtp(email, token, i18n.language).subscribe({
          next: () => {
            handleClientCreated()
            setSubmitIsLoading(false)
          },
        })
      },
    })
  }, [email, i18n.language, handleClientCreated, getRecaptchaToken])

  const handleSubmit = useCallback(
    (values: FormValues, actions) => {
      setSubmitIsLoading(true)

      let payload: ContactWithoutPasswordPayload = {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.contactNumber,
        email: email,
        language: i18n.language,
        authPortalBase: window.location.origin,
        callbackUrl: getCallbackURL(),
        originBase: getOriginBaseUrl(),
        vendorCode: getVendorCode(),
        clientCode: getClientCode(getClientPortalHost()) ?? '',
        recaptchaToken: '',
      }
      from(getRecaptchaToken(RecaptchaActions.Signup)).subscribe({
        next: (token) => {
          if (accountType === IAccountType.Client) {
            payload = {
              ...payload,
              companyName: values.clientName,
            }
          }

          payload = {
            ...payload,
            recaptchaToken: token,
          }

          authService
            .createContactWithoutPassword(payload, accountType)
            .subscribe({
              next: () => {
                if (accountType === IAccountType.Contractor) {
                  handleGenerateOtp()
                } else {
                  handleOpen()
                  setSubmitIsLoading(false)
                }
              },
              error: () => {
                setSubmitIsLoading(false)
                actions.resetForm({ values })
              },
            })
        },
      })
    },
    [
      accountType,
      handleOpen,
      handleGenerateOtp,
      email,
      getRecaptchaToken,
      i18n.language,
    ]
  )

  return (
    <>
      {isContractorRouteAllowed && (
        <Typography
          component="h1"
          variant="h30Bold800"
          align="center"
          my={6}
          lineHeight={1.5}
        >
          {t('form.createAccount.multiSelectHeading')}
        </Typography>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="form-root">
              {isContractorRouteAllowed && (
                <FormControl>
                  <RadioGroup
                    name="accountType"
                    row
                    onChange={(event, value) => {
                      setFieldValue('accountType', value)
                      setAccountType(value)
                    }}
                  >
                    {AccountTypeOptions.map((option) => (
                      <FormControlLabel
                        key={option.label}
                        label={t(option.label as TFuncKey)}
                        value={t(option.value as TFuncKey)}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}

              {values.accountType && (
                <Box mt={2}>
                  <Stack direction="row" gap={2} width="100%">
                    <Field
                      component={TextField}
                      name="firstName"
                      type="text"
                      label={t('form.createAccount.firstName')}
                      fullWidth
                    />

                    <Field
                      component={TextField}
                      name="lastName"
                      type="text"
                      label={t('form.createAccount.lastName')}
                      fullWidth
                    />
                  </Stack>

                  {values.accountType === IAccountType.Client && (
                    <Field
                      component={TextField}
                      name="contactNumber"
                      label={t('form.createAccount.phoneNumber')}
                      fullWidth
                      InputProps={{
                        inputComponent: PhoneInput,
                        startAdornment: (
                          <InputAdornment position="start">+1</InputAdornment>
                        ),
                      }}
                      onChange={(e: any) => {
                        setFieldValue(
                          'contactNumber',
                          e.target.value.replace(/\D/g, '')
                        )
                      }}
                    />
                  )}

                  {values.accountType === IAccountType.Client && (
                    <Field
                      component={TextField}
                      name="clientName"
                      type="text"
                      label={t('form.createAccount.companyName')}
                      fullWidth
                    />
                  )}

                  <LoadingButton
                    variant="contained"
                    size="large"
                    color="secondary"
                    type="submit"
                    sx={{ mb: 2.5, mt: 2 }}
                    fullWidth
                    loading={submitIsLoading}
                  >
                    {t('common.btn.submit')}
                  </LoadingButton>
                </Box>
              )}

              <Link
                component={RouterLink}
                underline="none"
                to="/"
                sx={{ mt: 5, mb: 2 }}
              >
                <Typography
                  variant="body1Bold700"
                  lineHeight={1.5}
                  component="span"
                >
                  {t('form.reset.backLoginBtn')}
                </Typography>
              </Link>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

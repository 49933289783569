import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Box, Button } from '@mui/material'
import {
  ClientNames,
  getClientName,
  getVendorCode,
  LoginTypes,
  openPopupWindow,
  popupWindowNames,
  SocialLoginType,
  SocialRoutes,
  VendorCodes,
} from '@procom-labs/common'
import { SocialMediaButtonGroup } from '@procom-labs/molecules'

import { environment } from '@auth-portal/environment'
import { OpenLinksEnum } from '@auth-portal/routes/routes.enum'
import { SocialLoginContext } from '@auth-portal/types'

export const SocialMediaLogin: React.FC<{
  context: SocialLoginContext
  loginType?: LoginTypes
}> = ({ context, loginType = LoginTypes.Contractor }) => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation('main')
  const navigate = useNavigate()
  const campaignId = searchParams.get('campaignId')

  const socialLoginTypes = useMemo(() => {
    if (loginType === LoginTypes.Customer) {
      return [SocialLoginType.Microsoft]
    }
    if (getClientName() === ClientNames.Flextrack) {
      return [SocialLoginType.Google, SocialLoginType.Facebook]
    }
    return [
      SocialLoginType.Google,
      SocialLoginType.Facebook,
      SocialLoginType.Linkedin,
    ]
  }, [loginType])

  const handleSocialMediaLogin = useCallback(
    (socialLoginType: SocialLoginType) => {
      localStorage.setItem('socialLoginContext', context)
      localStorage.setItem('socialLoginType', socialLoginType)

      const state = window.btoa(
        encodeURIComponent(
          JSON.stringify({
            campaignId: campaignId || '',
          })
        )
      )

      const socialLinkHref =
        socialLoginType === SocialLoginType.Linkedin
          ? `${
              environment.LINKEDIN.AUTH_URL
            }/authorization?response_type=code&client_id=${
              environment.LINKEDIN.CLIENT_ID
            }&redirect_uri=${encodeURIComponent(
              window.location.origin
            )}/signin-linkedin-callback&scope=r_liteprofile%20r_emailaddress%20w_member_social&state=${state}`
          : // eslint-disable-next-line max-len
            `${environment.cognito.AWS_AUTH_URL}/oauth2/authorize?client_id=${environment.cognito.AWS_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${window.location.origin}/${SocialRoutes.SocialCallbackURL}&identity_provider=${socialLoginType}&state=${state}`

      openPopupWindow(socialLinkHref, popupWindowNames.SocialLogin)
    },
    [campaignId, context]
  )

  return (
    <Box sx={{ mb: 5 }}>
      {[VendorCodes.PCGL, VendorCodes.DEMO].includes(getVendorCode()) && (
        <SocialMediaButtonGroup
          storageUrl={environment.BLOB_STORAGE_URL}
          handleClick={handleSocialMediaLogin}
          socialLoginTypes={socialLoginTypes}
        />
      )}
      {loginType === LoginTypes.Contractor && (
        <Box
          sx={{
            px: {
              xs: 1,
              sm: 4,
              md: 40,
              lg: 5,
              xl: 7,
              k2: 12.5,
            },
          }}
        >
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              navigate(OpenLinksEnum.LoginDigitCode)
            }}
            sx={{
              marginTop: 4,
            }}
          >
            {t('form.createAccount.oneTimeCode')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export enum AppTypes {
  auth = 'Auth Portal',
  client = 'Client Portal',
  submission = 'Submission Portal',
  resume = 'Resume Portal',
}

export enum SocialLoginType {
  Google = 'Google',
  Facebook = 'Facebook',
  Linkedin = 'Linkedin',
  Microsoft = 'Portal-Microsoft-SSO',
}

export enum SocialRoutes {
  SocialCallbackURL = 'signin-social-callback',
}

export enum LoginTypes {
  Client = 'Client',
  Contractor = 'Contractor',
  Employer = 'Employer',
  Customer = 'Customer',
  Support = 'Support',
  GorillaResume = 'GorillaResume',
}

export enum ClientNames {
  Procom = 'Procom',
  BMO = 'Bank of Montreal',
  DemoCorp = 'DemoCorp',
  DemoClient = 'Demo Client',
  HCMWorks = 'HCM Works',
  KPMG = 'KPMG',
  CENOVUS = 'Cenovus',
  ProcomTeam = 'Procom Team',
  Flextrack = 'Flextrack',
  GorillaWorks = 'GorillaWorks',
  Symbiotic = 'Symbiotic',
  Aplin = 'Aplin',
  Compustaff = 'Compustaff',
  EastWest = 'EastWest',
  Softchoice = 'Softchoice',
  VNDRHWN = 'VanderHouwen',
  Demo = 'Demo',
}

export enum CompanyNames {
  ClientConnections = 'Client Connections',
}

export enum ClientCodes {
  Procom = 'Procom',
  BMO = 'BMO',
  DemoCorp = 'DemoCorp',
  HCMWorks = 'HCMWorks',
  KPMG = 'KPMG',
  CENOVUS = 'Cenovus',
  ProcomTeam = 'ProcomTeam',
  Flextrack = 'Flextrack',
  Symbiotic = 'Symbiotic',
  DemoClient = 'DemoClient',
}

export enum VendorCodes {
  GW = 'GW',
  PCGL = 'PCGL',
  TSLA = 'TSLA',
  Symbiotic = 'Symbiotic',
  APLN = 'APLN',
  CMPSTF = 'CMPSTF',
  ESTWST = 'ESTWST',
  SFTCHE = 'SFTCHE',
  VNDRHWN = 'VNDRHWN',
  DEMO = 'DEMO',
  IMETHODS = 'IMETHODS',
}

export enum VendorNames {
  GW = 'GorillaWorks',
  PCGL = 'Procom Services Ltd',
  TSLA = 'Tesla Ltd',
  Symbiotic = 'Symbiotic',
  APLN = 'Aplin',
  CMPSTF = 'Compustaff',
  SFTCHE = 'Softchoice',
  ESTWST = 'East West',
  VNDRHWN = 'VanderHouwen',
  DEMO = 'Demo',
  IMETHODS = 'iMethods',
}

export enum IFrameEntityTypes {
  Candidate = 'Candidate',
}

export enum ClientDomainType {
  Local = 1,
  Develop = 2,
  Qa = 3,
  Uat = 4,
  Production = 5,
}

export enum ClientQueryParams {
  authPortalRoute = 'authPortalRoute',
  callbackURL = 'callbackURL', // URL to send the user back to his portal
  destinationURL = 'destinationURL', // Once the login request is complete on the client portal, user is redirected to this route.
  originBase = 'originBase',
  loginType = 'loginType',
  themeMode = 'themeMode',
}

export enum RecaptchaActions {
  Signup = 'signup',
  OpenJobApply = 'openJobApply',
  ResendInvitation = 'resendInvitation',
  ActivateFboMapping = 'activateFboMapping',
  ContractorJobShare = 'ContractorJobShare',
  GetAnonymousUserInfo = 'getAnonymousUserInfo',
  RequestResetPassword = 'requestResetPassword',
  ClaimContractorProfile = 'claimContractorProfile',
  ContractorProfileInfo = 'contractorProfileInfo',
  DeclineContractorProfile = 'declineContractorProfile',
  Mfa = 'AddOrRemoveMfa',
  CodeSignIn = 'codeSignin',
  GetRestrictedAccessCode = 'getRestrictedAccessCode',
  GuestSignIn = 'guestSignin',
  ValidateEmailDomain = 'validateEmailDomain',
  LanguageCopilot = 'languageCopilot',
}

export enum CommonMessages {
  SomethingWrong = 'somethingWrong',
  LoginFailed = 'LoginFailed',
}

export enum AuthPortalRouteMaps {
  Login = 'login',
  Signup = 'signup',
  SignupEmail = 'signupEmail',
  ResetExpiredPassword = 'resetExpiredPassword',
  VerifyEmail = 'verifyEmail',
  ResetPassword = 'resetPassword',
}

export enum AiFeatureLocations {
  ClientPortal = 'Client Portal',
  EmployerPortal = 'Employer Portal',
  Bullhorn = 'Bullhorn',
}

export enum PortalType {
  CLIENT = 'client',
  EMPLOYER = 'employer',
}

export enum EmailSupportLinks {
  ClientConnections = 'info@clientconnections.com',
  GorillaWorks = 'contact@gorillaworks.io',
}

export enum InterviewPlatforms {
  Zoom = 'Zoom',
  MicrosoftTeams = 'Microsoft Teams',
  InPerson = 'In Person',
  Other = 'Other',
}

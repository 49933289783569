import { alpha, PaletteMode, ThemeOptions } from '@mui/material'
import { purple } from '@mui/material/colors'

import { PaletteModeCode } from '../../enums'

export const getDarkThemeConfig = (
  paletteModeCode: PaletteModeCode
): ThemeOptions => {
  if (paletteModeCode === PaletteModeCode.dark) {
    return {
      palette: {
        mode: paletteModeCode as PaletteMode,
        action: {
          disabled: '#ffffffb3',
          disabledBackground: '#322E46',
        },
        common: {
          black: '#000',
          white: '#fff',
          purple: '#372E52',
          offWhite: '#FEF9F2',
          glass: 'rgba(233, 244, 254, 0.05)',
          glassOnGlass: 'rgba(233, 244, 254, 0.05)',
          jobColumnStatusHeader: '#fff',
          lightOrange: '#DB6900',
          orange: '#ef4a0c',
          pink: '#f7e0db',
          darkGray: '#333',
        },
        primary: {
          light: '#fff',
          main: '#fff',
          dark: '#fff',
          contrastText: '#372E52',
        },
        secondary: {
          light: '#ef4a0c',
          main: '#DB3A00',
          dark: '#b83101',
          contrastText: '#fff',
        },
        error: {
          dark: '#DB0000',
          main: '#F00',
          light: '#F88078',
          contrastText: '#fff',
        },
        warning: {
          main: '#ED6C02',
          light: '#FFB547',
          dark: '#C77700',
          contrastText: '#fff',
        },
        info: {
          dark: '#1d2b7a',
          main: '#303F9F',
          light: '#E9F4FE',
          contrastText: '#fff',
        },
        success: {
          light: '#7BC67E',
          main: '#367C3A',
          dark: '#3B873E',
          contrastText: '#fff',
        },
        text: {
          primary: '#fff',
          secondary: '#fff',
          disabled: '#ffffffb3',
          iconDisabled: '#ffffff33',
        },
        divider: '#fff',
        background: {
          default: '#221B37',
          paper: '#ffffff00',
        },
        candidatesFunnel: {
          new: '#303F9F',
          shortlist: '#145A7B',
          interview: '#73483A',
          offer: '#DB3A00',
          rejected: '#F00',
        },
        candidatesSource: {
          directlyApplied: '#C7D3F2',
          recruiterAdded: '#C1E3D3',
          aiMatched: '#DBDBDB',
          managerAdded: '#C1E3D3',
        },
        jobsFunnel: {
          active: '#3B873E',
          paused: '#ED6C02',
          closed: '#3A1528',
          draft: '#000000',
        },
        alertOutlined: {
          bgSuccess: '#EDF7ED',
          bgWarning: '#FDF0E6',
          bgError: '#FEECEB',
        },
        onePager: {
          skillsProgress: '#978CB6',
          skillsProgressBg: '#D6D6ED',
          jobsProgress: '#8CB6A7',
          jobsProgressBg: '#CEE4DC',
          boxBg: '#F4F5F7',
        },
        consultantList: {
          primary: '#DB3A00',
          secondary: '#FEF9F2',
          statusChips: {
            selected: '#372E52',
            border: '#d3d3d340',
          },
        },
      },
      components: {
        MuiAlert: {
          styleOverrides: {
            standard: ({ theme }) => ({
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.common.glass,
              '& .MuiAlert-icon': {
                color: theme.palette.common.white,
              },
            }),
          },
        },
        MuiSwitch: {
          styleOverrides: {
            root: ({ theme }) => ({
              '& .MuiSwitch-switchBase': {
                padding: '14px',
                '&.Mui-checked .MuiSwitch-thumb': {
                  backgroundColor: theme.palette.primary.contrastText,
                  borderColor: theme.palette.primary.contrastText,
                },
                '&.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: theme.palette.primary.main,
                  opacity: 1,
                },
              },
            }),
            thumb: {
              width: 10,
              height: 10,
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'text.primary',
            },
            track: {
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'text.primary',
              opacity: 1,
            },
          },
        },
      },
    }
  }

  if (paletteModeCode === PaletteModeCode.glass) {
    return {
      palette: {
        mode: paletteModeCode as PaletteMode,
        action: {
          disabled: '#ffffffb3',
          disabledBackground: '#3A3751',
        },
        common: {
          black: '#000',
          white: '#fff',
          purple: '#372E52',
          offWhite: '#FEF9F2',
          glass: 'rgba(233, 244, 254, 0.05)',
          orange: '#ef4a0c',
          pink: '#f7e0db',
        },
        primary: {
          light: '#fff',
          main: '#fff',
          dark: '#fff',
          contrastText: '#372E52',
        },
        secondary: {
          light: '#ef4a0c',
          main: '#DB3A00',
          dark: '#b83101',
          contrastText: '#fff',
        },
        error: {
          dark: '#DB0000',
          main: '#F00',
          light: '#F88078',
          contrastText: '#fff',
        },
        warning: {
          main: '#ED6C02',
          light: '#FFB547',
          dark: '#C77700',
          contrastText: '#fff',
        },
        info: {
          dark: '#1d2b7a',
          main: '#303F9F',
          light: '#E9F4FE',
          contrastText: '#fff',
        },
        success: {
          main: '#4CAF50',
          light: '#367C3A',
          dark: '#3B873E',
          contrastText: '#fff',
        },
        text: {
          primary: '#fff',
          secondary: '#fff',
          disabled: '#ffffffb3',
          iconDisabled: '#ffffff33',
        },
        divider: '#fff',
        background: {
          default: 'rgba(233, 244, 254, 0.05);',
          paper: 'rgba(233, 244, 254, 0.05);',
        },
        jobsFunnel: {
          active: '#367C3A',
          paused: '#303F9F',
          closed: '#F00',
          draft: '#FFF',
        },
        alertOutlined: {
          bgSuccess: '#EDF7ED',
          bgWarning: '#FDF0E6',
          bgError: '#FEECEB',
        },
        consultantList: {
          primary: '#DB3A00',
          secondary: '#FEF9F2',
          statusChips: {
            selected: '#372E52',
            border: '#d3d3d340',
          },
        },
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
              backdropFilter: 'blur( 3px )',
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: ({ theme }) => ({
              '&.Mui-error': {
                color: theme.palette.common.white,
              },
            }),
          },
        },
        MuiAlert: {
          styleOverrides: {
            standard: ({ theme }) => ({
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.common.glass,
              '& .MuiAlert-icon': {
                color: theme.palette.common.white,
              },
            }),
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root: ({ theme }) => ({
              '&.Mui-error': {
                color:
                  theme.palette.mode !== PaletteModeCode.light &&
                  theme.palette.mode !== PaletteModeCode.dark
                    ? theme.palette.common.white
                    : theme.palette.error.main,
              },
            }),
          },
        },
      },
    }
  }

  if (paletteModeCode === PaletteModeCode.sidebar) {
    return {
      palette: {
        mode: paletteModeCode as PaletteMode,
        common: {
          glassOnGlass: 'rgba(233, 244, 254, 0.05)',
          pink: '#f7e0db',
        },
        primary: {
          light: '#fff',
          main: '#fff',
          dark: '#fff',
          contrastText: '#372E52',
        },
        secondary: {
          light: '#ef4a0c',
          main: '#DB3A00',
          dark: '#b83101',
          contrastText: '#fff',
        },

        text: {
          primary: '#fff',
          secondary: '#fff',
          disabled: '#ffffffb3',
          iconDisabled: '#ffffff33',
        },
        divider: '#fff',
        background: {
          default: 'rgba(233, 244, 254, 0.05);',
          paper: 'rgba(233, 244, 254, 0.05)',
        },
        jobsFunnel: {
          active: '#367C3A',
          paused: '#303F9F',
          closed: '#F00',
          draft: '#FFF',
        },
        consultantList: {
          primary: '#DB3A00',
          secondary: '#FEF9F2',
          statusChips: {
            selected: '#372E52',
            border: '#d3d3d340',
          },
        },
      },
    }
  }

  return {
    palette: {
      mode: paletteModeCode as PaletteMode,
      action: {
        active: '#372E52',
        disabled: '#59595980',
        disabledBackground: '#F4EFE8',
        hover: 'rgb(0, 0, 0, 0.04)',
        selected: 'rgb(0, 0, 0, 0.08)',
      },
      common: {
        black: '#000',
        white: '#fff',
        purple: '#372E52',
        offWhite: '#FEF9F2',
        glass: 'rgba(233, 244, 254, 0.05)',
        lightOrange: '#DB6900',
        pink: '#f7e0db',
      },
      primary: {
        light: '#4f4379',
        main: '#372E52',
        dark: '#221B37',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ef4a0c',
        main: '#DB3A00',
        dark: '#b83101',
        contrastText: '#fff',
      },
      error: {
        dark: '#DB0000',
        main: '#F00',
        light: '#F88078',
        contrastText: '#fff',
      },
      warning: {
        main: '#ED6C02',
        light: '#FFB547',
        dark: '#C77700',
        contrastText: '#fff',
      },
      info: {
        dark: '#1d2b7a',
        main: '#303F9F',
        light: '#E9F4FE',
        contrastText: '#fff',
      },
      success: {
        main: '#4CAF50',
        light: '#367C3A',
        dark: '#3B873E',
        contrastText: '#fff',
      },
      text: {
        primary: '#372E52',
        secondary: '#595959',
        disabled: '#59595980',
        iconDisabled: '#59595980',
      },
      divider: '#372E52',
      background: {
        default: '#FEF9F2',
        paper: '#FEF9F2',
      },
      candidatesFunnel: {
        new: '#303F9F',
        shortlist: '#145A7B',
        interview: '#73483A',
        offer: '#DB3A00',
        rejected: '#F00',
      },
      jobsFunnel: {
        active: '#367C3A',
        paused: '#303F9F',
        closed: '#F00',
        draft: '#FFF',
      },
      alertOutlined: {
        bgSuccess: '#EDF7ED',
        bgWarning: '#FDF0E6',
        bgError: '#FEECEB',
      },
      gorillaResume: {
        ai: purple.A700,
      },
      consultantList: {
        primary: '#DB3A00',
        secondary: '#FEF9F2',
        statusChips: {
          selected: '#372E52',
          border: '#d3d3d340',
        },
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& .MuiSwitch-switchBase': {
              '&.Mui-checked .MuiSwitch-thumb': {
                backgroundColor: theme.palette.background.default,
              },
              '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
              },
            },
          }),
          thumb: ({ theme }) => ({
            width: 10,
            height: 10,
            backgroundColor: theme.palette.primary.main,
          }),
          track: {
            backgroundColor: 'transparent',
            opacity: 1,
            border: '1px solid',
            borderColor: 'text.primary',
          },
          switchBase: {
            padding: '13.5px',
            marginLeft: 2,
            '&.Mui-checked': {
              marginLeft: -1,
            },
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: `${theme.palette.primary.main}`,
            marginLeft: 0,
          }),
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '20px',
          },
        },
        defaultProps: {
          BackdropProps: {
            style: {
              fill: 'rgba(0, 0, 0, 0.54)',
              backdropFilter: 'blur(10px)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: ({ theme }) => ({
            borderColor: theme.palette.primary.main,
            '.MuiOutlinedInput-root:hover &': {
              borderWidth: 1.5,
            },
          }),
        },
      },
      MuiAlert: {
        styleOverrides: {
          standard: ({ theme }) => ({
            color: theme.palette.primary.main,
          }),
          standardError: ({ theme }) => ({
            backgroundColor: alpha(theme.palette.error.main, 0.095),
            '& .MuiAlert-icon': {
              color: theme.palette.error.main,
            },
          }),
          standardInfo: ({ theme }) => ({
            backgroundColor: '#F2EDE6',
            '& .MuiAlert-icon': {
              color: theme.palette.primary.main,
            },
          }),
          standardWarning: ({ theme }) => ({
            backgroundColor: alpha(theme.palette.error.main, 0.095),
            '& .MuiAlert-icon': {
              color: theme.palette.error.main,
            },
          }),
          standardSuccess: ({ theme }) => ({
            color: theme.palette.success.main,
          }),
        },
      },
    },
  }
}

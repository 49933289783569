import { PaletteMode, ThemeOptions } from '@mui/material'
import { purple } from '@mui/material/colors'

import { PaletteModeCode } from '../../enums'

export const getLightThemeConfig = (
  paletteModeCode: PaletteModeCode
): ThemeOptions => {
  if (paletteModeCode === PaletteModeCode.glass) {
    return {
      palette: {
        mode: paletteModeCode as PaletteMode,
        action: {
          disabled: '#797979',
          disabledBackground: '#adacaa29',
        },
        common: {
          black: '#000',
          white: '#fff',
          purple: '#0F214B',
          purpleLight: '#E2E7F0',
          offWhite: '#fff',
          glass: '#F4F5F7',
          orange: '#ef4a0c',
          pink: '#f7e0db',
          smokeWhite: '#F5F5F5',
          darkGray: '#333',
        },
        primary: {
          light: '#576381',
          main: '#0F214B',
          dark: '#0a1734',
          contrastText: '#fff',
        },
        secondary: {
          light: '#576381',
          main: '#0F214B',
          dark: '#0a1734',
          contrastText: '#fff',
        },
        error: {
          dark: '#DB0000',
          main: '#F00',
          light: '#F88078',
          contrastText: '#fff',
        },
        warning: {
          main: '#ED6C02',
          light: '#FFB547',
          dark: '#C77700',
          contrastText: '#fff',
        },
        info: {
          dark: '#1d2b7a',
          main: '#303F9F',
          light: '#E9F4FE',
          contrastText: '#fff',
        },
        success: {
          main: '#4CAF50',
          light: '#367C3A',
          dark: '#3B873E',
          contrastText: '#fff',
        },
        text: {
          primary: '#111111',
          secondary: '#111111',
          disabled: '#797979',
          iconDisabled: '#797979',
        },
        divider: '#0F214B',
        background: {
          default: '#F4F5F7',
          paper: '#F4F5F7',
        },
        candidatesSource: {
          directlyApplied: '#C7D3F2',
          recruiterAdded: '#C1E3D3',
          aiMatched: '#DBDBDB',
          managerAdded: '#C1E3D3',
        },
        jobsFunnel: {
          active: '#367C3A',
          paused: '#303F9F',
          closed: '#F00',
          draft: '#FFF',
        },
        alertOutlined: {
          bgSuccess: '#EDF7ED',
          bgWarning: '#FDF0E6',
          bgError: '#FEECEB',
        },
        consultantList: {
          primary: '#01718A',
          secondary: '#CFD3DB',
          statusChips: {
            selected: '#e6e6e6',
            border: '#d3d3d3',
          },
        },
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
              backdropFilter: 'blur( 3px )',
            },
          },
        },
      },
    }
  }

  if (paletteModeCode === PaletteModeCode.sidebar) {
    return {
      palette: {
        mode: paletteModeCode as PaletteMode,
        action: {
          disabled: '#797979',
          disabledBackground: '#3A3751',
          hover: 'rgba(233, 244, 254, 0.05)',
          selected: 'rgb(0, 0, 0, 0.08)',
        },
        common: {
          black: '#000',
          white: '#fff',
          purple: '#0F214B',
          purpleLight: '#E2E7F0',
          offWhite: '#FEF9F2',
          glass: 'rgba(233, 244, 254, 0.05)',
          glassOnGlass: '#0F214B',
          orange: '#ef4a0c',
          pink: '#f7e0db',
        },
        primary: {
          light: '#fff',
          main: '#fff',
          dark: '#0F214B',
          contrastText: '#111111',
        },
        secondary: {
          light: '#E67637',
          main: '#E67637',
          dark: '#E67637',
          contrastText: '#fff',
        },

        text: {
          primary: '#fff',
          secondary: '#fff',
          disabled: '#797979',
          iconDisabled: '#797979',
        },
        divider: '#0F214B',
        background: {
          default: '#0F214B',
          paper: '#0F214B',
        },
        jobsFunnel: {
          active: '#3B873E',
          paused: '#ED6C02',
          closed: '#3A1528',
          draft: '#000000',
        },
        consultantList: {
          primary: '#01718A',
          secondary: '#CFD3DB',
          statusChips: {
            selected: '#e6e6e6',
            border: '#d3d3d3',
          },
        },
      },
    }
  }

  return {
    palette: {
      mode: paletteModeCode as PaletteMode,
      logoMode: PaletteModeCode.light,
      action: {
        active: '#0F214B',
        disabled: '#797979',
        disabledBackground: '#adacaa29',
        hover: 'rgb(0, 0, 0, 0.04)',
        selected: 'rgb(0, 0, 0, 0.08)',
      },
      common: {
        black: '#000',
        white: '#fff',
        purple: '#0F214B',
        purpleLight: '#E2E7F0',
        offWhite: '#fff',
        glass: '#F4F5F7',
        glassOnGlass: '#fff',
        jobColumnStatusHeader: '#E9EBEE',
        lightOrange: '#31487C',
        orange: '#ef4a0c',
        pink: '#f7e0db',
      },
      primary: {
        light: '#576381',
        main: '#0F214B',
        dark: '#0a1734',
        contrastText: '#fff',
      },
      secondary: {
        light: '#576381',
        main: '#0F214B',
        dark: '#0a1734',
        contrastText: '#fff',
      },
      error: {
        dark: '#DB0000',
        main: '#F00',
        light: '#F88078',
        contrastText: '#fff',
      },
      warning: {
        main: '#ED6C02',
        light: '#FFB547',
        dark: '#C77700',
        contrastText: '#fff',
      },
      info: {
        dark: '#1d2b7a',
        main: '#303F9F',
        light: '#E9F4FE',
        contrastText: '#fff',
      },
      success: {
        light: '#7BC67E',
        main: '#367C3A',
        dark: '#3B873E',
        contrastText: '#fff',
      },
      text: {
        primary: '#111111',
        secondary: '#111111',
        disabled: '#797979',
        iconDisabled: '#00000030',
      },
      divider: '#0F214B',
      background: {
        default: '#fff',
        paper: '#fff',
      },
      candidatesFunnel: {
        new: '#303F9F',
        shortlist: '#145A7B',
        interview: '#73483A',
        offer: '#DB3A00',
        rejected: '#F00',
      },
      jobsFunnel: {
        active: '#3B873E',
        paused: '#ED6C02',
        closed: '#3A1528',
        draft: '#000000',
      },
      alertOutlined: {
        bgSuccess: '#EDF7ED',
        bgWarning: '#FDF0E6',
        bgError: '#FEECEB',
      },
      onePager: {
        skillsProgress: '#978CB6',
        skillsProgressBg: '#D6D6ED',
        jobsProgress: '#8CB6A7',
        jobsProgressBg: '#CEE4DC',
        boxBg: '#F4F5F7',
      },
      gorillaResume: {
        ai: purple.A700,
      },
      consultantList: {
        primary: '#01718A',
        secondary: '#CFD3DB',
        statusChips: {
          selected: '#e6e6e6',
          border: '#d3d3d3',
        },
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: '#fff',
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& .MuiSwitch-switchBase': {
              '&.Mui-checked .MuiSwitch-thumb': {
                backgroundColor: theme.palette.background.default,
              },
              '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
              },
            },
          }),
          thumb: ({ theme }) => ({
            width: 10,
            height: 10,
            backgroundColor: theme.palette.primary.main,
          }),
          track: {
            backgroundColor: 'transparent',
            opacity: 1,
            border: '1px solid',
            borderColor: 'text.primary',
          },
          switchBase: {
            padding: '13.5px',
            marginLeft: 2,
            '&.Mui-checked': {
              marginLeft: -1,
            },
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: `${theme.palette.primary.main}`,
            marginLeft: 0,
          }),
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '20px',
          },
        },
        defaultProps: {
          BackdropProps: {
            style: {
              fill: 'rgba(0, 0, 0, 0.54)',
              backdropFilter: 'blur(10px)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: ({ theme }) => ({
            borderColor: theme.palette.primary.main,
            '.MuiOutlinedInput-root:hover &': {
              borderWidth: 1.5,
            },
          }),
        },
      },

      MuiLinearProgress: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  }
}

import { VendorCodes, VendorNames } from '../enums'

const mapper: Record<VendorCodes, VendorNames> = {
  [VendorCodes.GW]: VendorNames.GW,
  [VendorCodes.PCGL]: VendorNames.PCGL,
  [VendorCodes.TSLA]: VendorNames.TSLA,
  [VendorCodes.Symbiotic]: VendorNames.Symbiotic,
  [VendorCodes.APLN]: VendorNames.APLN,
  [VendorCodes.CMPSTF]: VendorNames.CMPSTF,
  [VendorCodes.ESTWST]: VendorNames.ESTWST,
  [VendorCodes.SFTCHE]: VendorNames.SFTCHE,
  [VendorCodes.VNDRHWN]: VendorNames.VNDRHWN,
  [VendorCodes.DEMO]: VendorNames.DEMO,
  [VendorCodes.IMETHODS]: VendorNames.IMETHODS,
}

export const mapVendorCodeToName = (
  vendorCode: string | undefined
): string | undefined => {
  if (!vendorCode) return vendorCode
  return mapper[vendorCode as VendorCodes]
}
